<template>
  <div class="list_box">
    <LEFTNAV></LEFTNAV>
    <div class="box" v-bind:style="{ minHeight: Height + 'px' }">
      <div class="box_part" v-bind:style="{ minHeight: Height - 40 + 'px' }">
        <div class="fil_upload">
          <p class="trans_title">
            报告列表
            <!-- <img src="../../../assets/imgs/circle.png" alt="" /> -->
          </p>
          <div class="tables">
            <el-table
              ref="multipleTable"
              :data="historyFileList"
              tooltip-effect="dark"
            >
              <el-table-column label="文件名称" prop="filename" align="left">
              </el-table-column>

              <el-table-column
                label="上传时间"
                prop="create_time"
                width="200"
                align="center"
              >
              
              </el-table-column>

              <el-table-column
                label="状态"
                prop="state"
                width="180"
                align="center"
              >
                <template slot-scope="scope">
                  <el-tag
                    type="info"
                    v-if="
                      scope.row.state == '待处理' ||
                      scope.row.state == '待翻译'
                    "
                    >{{ scope.row.state }}</el-tag
                  >
                  <el-tag
                    type="danger"
                    v-if="
                      scope.row.state == '处理失败' ||
                      scope.row.state == '翻译失败'
                    "
                    >{{ scope.row.state }}</el-tag
                  >
                  <el-tag
                    v-if="
                      scope.row.state == '处理中'
                    "
                    >{{ scope.row.state }}</el-tag
                  >
                  <el-tag
                    type="success"
                    v-if="
                      scope.row.state == '处理完成'
                    "
                    >{{ scope.row.state }}</el-tag
                  >
                </template>
              </el-table-column>
              <el-table-column label="是否翻译" width="150" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.is_tran == 1">是</span>
                  <span v-if="scope.row.is_tran == 0">否</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="100">
                <template slot-scope="scope">
                  <div class="cells">
                    <el-button
                      size="mini"
                      round
                      class="down_css"
                      :disabled="scope.row.down_link == '' ? true : false"
                      @click="link(scope.row.down_link)"
                      >文件下载</el-button
                    >
                  </div>
                </template>
              </el-table-column>
              <template slot="empty">
                <div class="imgs">
                  <img
                    src="../../assets/imgs/zanwushuj.png"
                    alt=""
                    style="width: 50%"
                  />
                </div>
              </template>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LEFTNAV from "./component/leftNav.vue";
import {
  detail_list,
  input_file,
  download,
  get_limit,
} from "@/Api/scholarcy_writeful";
export default {
  data() {
    return {
      Height: 0,
      historyFileList: [],
      timer: null, // 刷新的定时器
    };
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 140; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 140;
    };
  },
  created() {
    this.getList();
    this.timer = setInterval(() => {
      this.getList();
    }, 30000);
  },
  beforeDestroy() {
    // 清空定时器
    clearInterval(this.timer);
  },

  methods: {
    getList() {
      let ids = {
        open_id: localStorage.getItem("code"),
        organ_name: localStorage.getItem("org_name"),
      };
      detail_list(ids)
        .then((res) => {
          console.log(res);
          this.historyFileList = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    link(url) {
      console.log(url);
      window.open(url, "_blank");
    },
  },
  components: {
    LEFTNAV,
  },
};
</script>

<style lang="less" scoped>
.list_box {
  padding-top: 75px;
  min-width: 1360px;
  display: flex;
  color: #000000;
  .box {
    padding: 20px;

    width: 100%;
    .box_part {
      //   background: white;
      width: 100%;
      //   padding: 20px;
    }
  }
}
.fil_upload {
  width: 100%;
  // height: 1200px;
  background: white;
  padding: 30px 100px;
  min-height: calc(100vh - 200px);
  // margin: 10px;
}
.trans_title {
  font-size: 24px;
  font-weight: bold;
  color: #55596e;
  text-align: center;
  margin-bottom: 35px;
}
.tables {
  // width: 100%;
  width: 90%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: center;
  .el-table {
    min-width: 1070px;
    max-width: 1450px;
  }
}
.down_css {
  //     background-image: linear-gradient(rgb(104, 97, 201), rgb(126, 103, 219));
  //     color: white;
}
</style>