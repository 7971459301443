var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list_box"},[_c('LEFTNAV'),_c('div',{staticClass:"box",style:({ minHeight: _vm.Height + 'px' })},[_c('div',{staticClass:"box_part",style:({ minHeight: _vm.Height - 40 + 'px' })},[_c('div',{staticClass:"fil_upload"},[_c('p',{staticClass:"trans_title"},[_vm._v(" 报告列表 ")]),_c('div',{staticClass:"tables"},[_c('el-table',{ref:"multipleTable",attrs:{"data":_vm.historyFileList,"tooltip-effect":"dark"}},[_c('el-table-column',{attrs:{"label":"文件名称","prop":"filename","align":"left"}}),_c('el-table-column',{attrs:{"label":"上传时间","prop":"create_time","width":"200","align":"center"}}),_c('el-table-column',{attrs:{"label":"状态","prop":"state","width":"180","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                    scope.row.state == '待处理' ||
                    scope.row.state == '待翻译'
                  )?_c('el-tag',{attrs:{"type":"info"}},[_vm._v(_vm._s(scope.row.state))]):_vm._e(),(
                    scope.row.state == '处理失败' ||
                    scope.row.state == '翻译失败'
                  )?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v(_vm._s(scope.row.state))]):_vm._e(),(
                    scope.row.state == '处理中'
                  )?_c('el-tag',[_vm._v(_vm._s(scope.row.state))]):_vm._e(),(
                    scope.row.state == '处理完成'
                  )?_c('el-tag',{attrs:{"type":"success"}},[_vm._v(_vm._s(scope.row.state))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"是否翻译","width":"150","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.is_tran == 1)?_c('span',[_vm._v("是")]):_vm._e(),(scope.row.is_tran == 0)?_c('span',[_vm._v("否")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"cells"},[_c('el-button',{staticClass:"down_css",attrs:{"size":"mini","round":"","disabled":scope.row.down_link == '' ? true : false},on:{"click":function($event){return _vm.link(scope.row.down_link)}}},[_vm._v("文件下载")])],1)]}}])}),_c('template',{slot:"empty"},[_c('div',{staticClass:"imgs"},[_c('img',{staticStyle:{"width":"50%"},attrs:{"src":require("../../assets/imgs/zanwushuj.png"),"alt":""}})])])],2)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }